import {Collapse} from "@mui/material";
import React, {useMemo} from "react";

import {useTranslation} from "@i18n/client";
import {useFetchCareEntries} from "../../../../queries/useFetchCareEntries";
import Slide, {TransitionPosition} from "../../../animations/Slide";
import {CareEntryRowVariant, ExtendedCareEntrySearchResult} from "../../CareEntryRow";
import {TextInput} from "../../Input";
import {
  CareDiscoveryRowClickReporter,
  getSendClickedCareDiscoveryRowEvent,
} from "./careDiscoveryAnalytics";
import {getAllRelatedCares} from "./getAllRelatedCares";
import NoResults from "./NoResults";
import {useCareDiscoverySearchController} from "./useCareDiscoverySearchController";
import {useAreRecursiveTopicsEnabled} from "./useTopicStack";
import ResultList from "@components/Reason/CareDiscoverySearch/ResultList";
import {getBorder} from "@components/Reason/CareDiscoverySearch/ResultList/border";
import {useHoveredIndexController} from "../../../../hooks/useHoveredIndexController";
import {useLoadApptReasons} from "../../../../hooks/useLoadApptReasons";

const emptyList: string[] = [];

export type RenderTopicInputs = {
  care: ExtendedCareEntrySearchResult;
  result: ExtendedCareEntrySearchResult;
  index: number;
  reportClickEvent: CareDiscoveryRowClickReporter;
};

const TopicScreen = ({
  care,
  in: _in,
  careEntryRowVariant,
  isParentSearchActive,
  from,
  render,
}: {
  care: ExtendedCareEntrySearchResult;
  from: TransitionPosition;
  careEntryRowVariant: CareEntryRowVariant;
  in?: boolean;
  isParentSearchActive?: boolean;
  render: (inputs: RenderTopicInputs) => React.ReactNode;
}) => {
  const {t} = useTranslation();
  const shouldShowSearch = useAreRecursiveTopicsEnabled() && getAllRelatedCares(care).length > 8;
  const relatedTopicSlugs: string[] = useMemo(
    () => care.related_topics?.map(relatedTopic => relatedTopic.slug) || emptyList,
    [care.related_topics],
  );
  const careEntriesQuery = useFetchCareEntries(relatedTopicSlugs);
  const careEntries = useMemo(
    () => [...careEntriesQuery.data, ...(care.cares || [])],
    [care.cares, careEntriesQuery.data],
  );
  const {activeIndex, setHovered, unsetHovered} = useHoveredIndexController();
  const reasons = useLoadApptReasons();

  const {
    isNoSymptomsSearched,
    handleQueryChange,
    searchCareEntriesQuery,
    showDefaultResults,
    showNoResults,
    resultsToRender,
  } = useCareDiscoverySearchController(careEntries, reasons);

  const loadingRowCount =
    showDefaultResults && careEntriesQuery.isLoading
      ? relatedTopicSlugs.length
      : searchCareEntriesQuery.isLoading
      ? 5
      : undefined;

  const reportClickEvent =
    isParentSearchActive || !showDefaultResults
      ? getSendClickedCareDiscoveryRowEvent("searched")
      : getSendClickedCareDiscoveryRowEvent("base");

  return (
    <Slide from={from} in={_in} duration={125} timeout={0} appear unmountOnExit>
      <div data-cy={`topic-expansion-${care.slug}`}>
        {shouldShowSearch && (
          <TextInput
            onChange={handleQueryChange}
            icon="search"
            placeholder={t("Search symptoms or services")}
            ariaLabel={t("Search symptoms or services")}
            data-cy={`topic-expansion-${care.slug}-discovery-search-input`}
            inputMode="text"
          />
        )}
        {showDefaultResults && (
          <span className="fs12 font-mr ttu gray600 pl-2 block pt-3">{care.name}</span>
        )}
        <Collapse in={showNoResults} unmountOnExit appear timeout={125}>
          <NoResults isNoSymptomsSearched={isNoSymptomsSearched} />
        </Collapse>
        {loadingRowCount ? (
          <ResultList.Loading
            reasons={reasons}
            loadingRowCount={loadingRowCount}
            keyPrefix={`topic-expansion-results-${care.slug}`}
            hideBorders
            rowVariant={careEntryRowVariant}
          />
        ) : (
          <ResultList className="mt-2 gap-1" onMouseLeave={unsetHovered}>
            {resultsToRender.map((result, i, arr) => {
              return (
                <ResultList.Item
                  className={getBorder(i, activeIndex, arr.length, true)}
                  key={`topic-expansion-results-${care.slug}-${i}-${result.name}`}
                  onMouseEnter={() => setHovered(i)}
                  transitionSpeed={0}
                >
                  {render({care, result, index: i, reportClickEvent})}
                </ResultList.Item>
              );
            })}
          </ResultList>
        )}
      </div>
    </Slide>
  );
};

export default React.memo(TopicScreen);
