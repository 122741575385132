import {useCallback, useMemo} from "react";

import {QueryState, useQueryController} from "../hooks/useQueryController";
import {memoizedFetchCareEntry} from "../utils/cms/cms";
import {CareEntrySearchResult} from "../utils/cms/types";

const maybeGetContent = (
  formattedCareEntryResponses: Awaited<ReturnType<typeof memoizedFetchCareEntry>>[],
) =>
  formattedCareEntryResponses.reduce<CareEntrySearchResult[]>(
    (acc, next) => (!next ? acc : [...acc, next.content]),
    [],
  );

export const useFetchCareEntries = (
  slugs: string[] | readonly string[],
): QueryState<CareEntrySearchResult[]> => {
  const defaultValue = useMemo(() => [], []);
  const fn = useCallback(
    () => Promise.all(slugs.map(memoizedFetchCareEntry)).then(maybeGetContent),
    [slugs],
  );

  return useQueryController({
    fn,
    initialValue: defaultValue,
    cacheKey: JSON.stringify(slugs),
  });
};
